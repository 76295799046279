import React, { useState } from 'react';
import clsx from 'clsx';
import Compliance from 'svgs/vendors/Compliance';
import FindVendors from 'svgs/vendors/FindVendors';
import FuelStops from 'svgs/vendors/FuelStops';
import Rentals from 'svgs/vendors/Rentals';
import Scales from 'svgs/vendors/Scales';
import Stays from 'svgs/vendors/Stays';
import TireSales from 'svgs/vendors/TireSales';
import Towing from 'svgs/vendors/Towing';
import TrailerRepair from 'svgs/vendors/TrailerRepair';
import TruckRepair from 'svgs/vendors/TruckRepair';
import TruckStops from 'svgs/vendors/TruckStops';
import TruckWash from 'svgs/vendors/TruckWash';
import Warehousing from 'svgs/vendors/Warehousing';

import VendorModal from '../../components/vendorModal/Modal';
import ArrowBottom from '../../svgs/ArrowBottom';
import ArrowTop from '../../svgs/ArrowTop';

import ModalMobile from './modalMobile/ModalMobile';
import RegisterModalMobile from './registerModalMobile/registerModalMobile';
import SignUpContentMobile from './signUpContentMobile/signUpContentMobile';
import MobileVersionData from './MobileVersionData';

import styles from './MobileVersion.module.scss';

interface IMobileVersion {
  modeSwitcher: boolean;
}

const MobileVersion = ({ modeSwitcher }: IMobileVersion) => {
  const [activeId, setActiveId] = useState('');
  const [openSignUpModal, setOpenSignUpModal] = useState(false);
  const [openVendorModal, setOpenVendorModal] = useState(false);

  const toggleCollapse = (id: string) => {
    if (id === activeId) {
      setActiveId('');
    } else {
      setActiveId(id);
    }
  };

  const handleContinue = () => {
    if (Number(activeId) === 1) {
      setActiveId('');
      window.location.href = 'http://app.freightmax.com/carrierotp';
    } else {
      setOpenSignUpModal(true);
    }
  };

  const onClose = () => {
    setOpenSignUpModal(false);
    setOpenVendorModal(false);
  };

  const activeCircle = {
    1: 'Carrier',
    2: 'Broker',
    3: 'Dispatcher',
    4: 'Shipper',
    5: 'Factoring company',
  };

  const vendors = [
    { id: 1, name: 'Truck repair & Shops', icon: <TruckRepair size="56px" /> },
    { id: 2, name: 'Truck Wash', icon: <TruckWash size="56px" /> },
    { id: 3, name: 'Warehousing & Crossdocs', icon: <Warehousing size="56px" /> },
    { id: 4, name: 'Towing & Recovery', icon: <Towing size="56px" /> },
    { id: 5, name: 'Fuel Stops/Gas Stations', icon: <FuelStops size="56px" /> },
    { id: 6, name: 'Tire Sales & Repair', icon: <TireSales size="56px" /> },
    { id: 7, name: 'Stays', icon: <Stays size="56px" /> },
    { id: 8, name: 'Scales & Weigh Stations', icon: <Scales size="56px" /> },
    { id: 9, name: 'Trailer Repair & Refrigeration unit', icon: <TrailerRepair size="56px" /> },
    { id: 10, name: 'Compliance & IRP', icon: <Compliance size="56px" /> },
    { id: 11, name: 'Truck Stops & Rest Areas', icon: <TruckStops size="56px" /> },
    { id: 12, name: 'Rentals', icon: <Rentals size="56px" /> },
    { id: 13, name: 'Find Vendors', icon: <FindVendors size="56px" /> },
  ];

  return (
    <div
      className={clsx([styles.carComp], {
        [styles.lightMode]: modeSwitcher,
      })}
    >
      <div
        className={clsx([styles.aboutUs], {
          [styles.lightAboutUs]: modeSwitcher,
        })}
      >
        <video
          playsInline
          preload="auto"
          controls
          width="575px"
          height="100%"
          poster="assets/FM-preview.png"
          style={{ maxWidth: '100%' }}
        >
          <source src="assets/FreightMax.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className={styles.collapseWrapper}>
        {MobileVersionData.map(el => {
          return (
            <div id={el.id}>
              <div
                className={clsx([styles.collapse], {
                  [styles.isOpen]: activeId !== el.id,
                  [styles.lightCollapse]: modeSwitcher,
                })}
              >
                <div>{el.title}</div>
                <button
                  className={clsx([styles.btn], {
                    [styles.lightBtn]: modeSwitcher,
                  })}
                  onClick={() => toggleCollapse(el.id)}
                >
                  {activeId !== el.id ? 'Learn more' : 'Close'}
                  {activeId !== el.id ? (
                    <ArrowBottom modeSwitcher={modeSwitcher} />
                  ) : (
                    <ArrowTop modeSwitcher={modeSwitcher} />
                  )}
                </button>
              </div>
              {el.id === activeId && (
                <div style={{ width: '100%', height: '1px', background: '#D9D9D9', margin: '0 auto' }} />
              )}

              {activeId === el.id && (
                <RegisterModalMobile activeCircle={activeId} lightMode={modeSwitcher} onClick={handleContinue} />
              )}
            </div>
          );
        })}
      </div>
      <div id="vendors" className={styles.vendorsWrapper}>
        <p className={styles.title} style={{ color: modeSwitcher ? '#252733' : '#ffffff' }}>
          Vendors
        </p>
        <div className={styles.vendors}>
          {vendors.map(el => (
            <div key={el.id} className={styles.vendor} onClick={() => setOpenVendorModal(prev => !prev)}>
              {el.icon}
              {el.name}
            </div>
          ))}
        </div>
      </div>
      {openSignUpModal && (
        <ModalMobile header={`I’m a ${activeCircle[activeId]}`} onClose={onClose}>
          <SignUpContentMobile name={`${activeCircle[activeId]}`} onClose={onClose} />
        </ModalMobile>
      )}
      {openVendorModal && <VendorModal onClose={onClose} size="small" modeSwitcher={modeSwitcher} />}
    </div>
  );
};

export default MobileVersion;
